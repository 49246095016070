import React from "react";
import { Container } from "react-bootstrap";
const Footer = () => {
  return (
    <>
      <Container>
        <div className="footer ">
          <h3> Gizem Ayaz Yegül © 2023</h3>
        </div>
      </Container>
    </>
  );
};

export default Footer;
